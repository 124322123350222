import React from "react";
import Link from "next/link";

import Modal from "./modal";
import { Button, P } from "../../ui";

function UpgradeTeamPlanModal({ toggle, isOpen, description }) {
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-lg" closeButton>
      <div className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline"
          >
            Upgrade Account
          </h3>
        </div>
        <div>
          <P>
            {description}
          </P>
        </div>
        <Link href="/plans" className="mt-6 mb-1 sm:flex">
          {" "}
          <Button tag="button" id="btn-viewplans">View Plans & Pricing</Button>
        </Link>
      </div>
    </Modal>
  );
}

export default UpgradeTeamPlanModal;
